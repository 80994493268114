import React, {Component} from 'react'
import './ScrollTop.scss'
import {scrollTo} from '../../../functions'

export default class ScrollTop extends Component {
    state = {
        scrollV: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = (e) => {
        if (e.srcElement.scrollingElement.scrollTop > 100 && this.state.scrollV === false) {
            this.setState({
                scrollV: true
            })
        } else if (e.srcElement.scrollingElement.scrollTop <= 100 && this.state.scrollV === true) {
            this.setState({
                scrollV: false
            })
        }
    }

    render() {
        return (
            <button
                onClick={() => scrollTo('app')}
                className={`scroll-to-top ${!this.state.scrollV ? 'scroll-to-top_hide' : ''}`}>
                <i className={`fa fa-angle-up`} aria-hidden="true"/>
            </button>
        )
    }
}