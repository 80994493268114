import React, {Component} from 'react'
import './Header.scss'
import {FormattedMessage} from 'react-intl'
import {scrollTo} from '../../functions'
import {NavLink, withRouter} from 'react-router-dom'
import Menu from '../UI/Menu/Menu'

class Header extends Component {
    state = {
        menuIsOpen: false
    }

    interactWithMenu = () => this.setState({menuIsOpen: !this.state.menuIsOpen})

    renderMainLinks = (menuIsOpen = this.state.menuIsOpen) =>
        <>
            <span
                onClick={() => {
                    scrollTo('company')
                    menuIsOpen && this.interactWithMenu()
                }}
                className="header__link">
                <FormattedMessage id="header.company"/>
            </span>
            <span
                onClick={() => {
                    scrollTo('expertise')
                    menuIsOpen && this.interactWithMenu()
                }}
                className="header__link">
                <FormattedMessage id="header.expertise"/>
            </span>
            <span
                onClick={() => {
                    scrollTo('webGeo')
                    menuIsOpen && this.interactWithMenu()
                }}
                className="header__link">
                <FormattedMessage id="header.webGeo"/>
            </span>
            <span
                onClick={() => {
                    scrollTo('contacts')
                    menuIsOpen && this.interactWithMenu()
                }}
                className="header__link">
                <FormattedMessage id="header.contacts"/>
            </span>
        </>

    renderSwitchLang = () => {
        const {setLocale, locale} = this.props
        return (
            <div className={'header__switch-lang'}>
                <span
                    onClick={() => setLocale('ru')}
                    className={`header__lang ${locale === 'ru' ? 'header__lang_active' : ''}`}>
                    Ru
                </span>
                <span
                    onClick={() => setLocale('en')}
                    className={`header__lang ${locale === 'en' ? 'header__lang_active' : ''}`}>
                    En
                </span>
            </div>
        )
    }


    renderToHome = () =>
        <NavLink onClick={() => this.state.menuIsOpen && this.interactWithMenu()} to={'/'} className={`header__link`}>
            <FormattedMessage id="header.home"/>
        </NavLink>

    render() {
        const {history: {location: {pathname}}} = this.props
        const {menuIsOpen} = this.state
        const navLinks = pathname === '/' ? this.renderMainLinks() : this.renderToHome()
        return (
            <div className={'header'}>
                <div className={'header__container'}>
                    <NavLink to={'/'} className={'header__logo'}>
                        <FormattedMessage id="header.logo"/>
                    </NavLink>

                    <div className={'w-100 d-none d-md-block'}>
                        <div className={'header__content'}>
                            <div className={'header__links'}>
                                {navLinks}
                            </div>
                            {
                                this.renderSwitchLang()
                            }
                        </div>
                    </div>

                    <div className={'w-100 d-block d-md-none'}>
                        <div className={'header__menu'}>
                            <Menu
                                menuIsOpen={menuIsOpen}
                                interactWithMenu={this.interactWithMenu}
                            />
                        </div>
                    </div>
                </div>

                {
                    menuIsOpen &&
                    <div className={'w-100 d-block d-md-none'}>
                        <div className={'header__content-mobile'}>
                            <div className={'header__links-mobile'}>
                                {navLinks}
                            </div>
                            {
                                this.renderSwitchLang()
                            }
                        </div>
                    </div>
                }
            </div>

        )
    }
}

export default withRouter(Header)