import React, {Component} from 'react'
import './MainPage.scss'
import {FormattedMessage} from 'react-intl'
import Wrapper from '../UI/Wrapper/Wrapper'
import ContactsForm from '../ContactsForm/ContactsForm'
import Arrow from '../UI/Arrow/Arrow'
import {scrollTo} from '../../functions'
import {WOW} from 'wowjs'

export default class MainPage extends Component {
    constructor(props) {
        super(props)

        const wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: window.innerHeight - 120,
            mobile: true,
            live: true
        })
        wow.init()
    }

    render() {
        return (
            <div className={'main-page'}>

                <section className={'wow fadeInDown section-badge'}>
                    <div className={'section-badge__background'}>
                        <div className={'container h-100'}>
                            <div className={'section-badge__content'}>
                                <div className={'title_row row'}>
                                    <div className={'col-12'}>
                                        <div className="section-badge__title">
                                            Accelerated Computing
                                        </div>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className="col-lg-3 col-md-2 col-sm-0"/>
                                    <div className="col-lg-6 col-md-8 col-sm-12">
                                        <span className={'text text_md'}>
                                             <FormattedMessage id="badge.text"/>
                                        </span>
                                    </div>
                                    <div className="col-lg-3 col-md-2 col-sm-0"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className={'arrow-row row'}>
                    <div className="col-12">
                        <Arrow onClick={() => scrollTo('company')}/>
                    </div>
                </div>


                <section id={'company'} className="section-about-us wow slideInRight">
                    <Wrapper>
                        <p className={'text text_xl'}>
                            <FormattedMessage id="about.accelcomp"/>
                        </p>
                        <p className={'text text_md'}>
                            <FormattedMessage id="about.offer"/>
                        </p>
                        <p className={'text text_md'}>
                            <FormattedMessage id="about.weCan"/>
                        </p>
                        <p className={'text text_md'}>
                            <FormattedMessage id="about.clusters"/>
                        </p>
                        <p className={'text text_md'}>
                            <FormattedMessage id="about.priority"/>
                        </p>
                    </Wrapper>
                </section>

                <section id={'expertise'} className="section-expertise wow slideInLeft">
                    <Wrapper>
                        <p className={'text text_xl-b text-center'}>
                            <FormattedMessage id="header.expertise"/>
                        </p>

                        <p className={'text text_lg text-center'}>
                            <FormattedMessage id="expertise.listHeader"/>
                        </p>

                        <ul className={'section-expertise__list text text_md'}>
                            <li><FormattedMessage id="expertise.item1"/></li>
                            <li><FormattedMessage id="expertise.item2"/></li>
                            <li><FormattedMessage id="expertise.item3"/></li>
                            <li><FormattedMessage id="expertise.item4"/></li>
                            <li><FormattedMessage id="expertise.item5"/></li>
                            <li><FormattedMessage id="expertise.item6"/></li>
                            <li><FormattedMessage id="expertise.item7"/></li>
                            <li><FormattedMessage id="expertise.item8"/></li>
                        </ul>
                    </Wrapper>
                </section>
                <section id={'webGeo'} className="section-web-geo wow slideInLeft">
                    <Wrapper>
                        <p className={'text text_xl-b text-center'}>
                            <FormattedMessage id="webGeo.title"/>
                        </p>
                        <p className={'text text_lg text-center'}>
                            <FormattedMessage id="webGeo.about"/>
                        </p>

                        <p className={'text text_lg-b'}>
                            <FormattedMessage id="webGeo.minConfig"/>
                        </p>

                        <ul className='section-web-geo__list text text_md'>
                            <li><FormattedMessage id="webGeo.minItem1"/></li>
                            <li><FormattedMessage id="webGeo.minItem2"/></li>
                            <li><FormattedMessage id="webGeo.minItem3"/></li>
                        </ul>

                        <p className={'text text_lg-b'}>
                            <FormattedMessage id="webGeo.config"/>
                        </p>

                        <ul className='section-web-geo__list text text_md '>
                            <li><FormattedMessage id="webGeo.item1"/></li>
                            <li><FormattedMessage id="webGeo.item2"/></li>
                            <li><FormattedMessage id="webGeo.item3"/></li>
                        </ul>

                    </Wrapper>
                </section>
                <section className="section-form wow slideInRight">
                    <Wrapper>
                        <ContactsForm sendMessage={this.props.sendMessage}/>
                    </Wrapper>
                </section>
            </div>
        )
    }
}