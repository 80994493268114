import React from 'react'
import './Wrapper.scss'

const Wrapper = (props) => {
    return (
        <div className="wrapper container">
            <div className="row">
                <div className="col-lg-2 col-md-1 col-sm-0"/>
                <div className="col-lg-8 col-md-10 col-sm-12">
                    {props.children}
                </div>
                <div className="col-lg-2 col-md-1 col-sm-0"/>
            </div>
        </div>
    )
}

export default Wrapper