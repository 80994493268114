import React from 'react'
import './Menu.scss'

const Menu = (props) => {
    const {menuIsOpen, interactWithMenu} = props
    return (
        <div className={`menu ${menuIsOpen ? 'menu_opened ' : ''}`} onClick={interactWithMenu}>
            <span/> <span/> <span/> <span/>
        </div>
    )
}

export default Menu