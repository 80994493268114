import './Page404.scss'
import {NavLink} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import React from 'react'

const Page404 = () => {
    return (
        <div className={'page-404'}>
            <div className={'page-404__content wow bounceIn'}>
                <span className={'hit-the-floor'}>404</span>
                <span className={'hit-the-floor hit-the-floor_mini'}><FormattedMessage id="404.notFound"/></span>
                <NavLink to={'/'} className={'btn btn-danger mt-4'}>
                    <FormattedMessage id="404.homepage"/>
                    <i className="fa fa-home ml-1" aria-hidden="true"/>
                </NavLink>
            </div>
        </div>
    )
}

export default Page404