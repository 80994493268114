import './App.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'toasted-notes/src/styles.css'
import Header from './components/Header/Header'
import React, {Component} from 'react'
import internationalization from './internationalization'
import {IntlProvider} from 'react-intl'
import MainPage from './components/MainPage/MainPage'
import ScrollTop from './components/UI/ScrollTop/ScrollTop'
import {showNotify} from './functions'
import PrivatePolicy from './components/PrivatePolicy/PrivatePolicy'
import {Switch, Route} from 'react-router-dom'
import Page404 from './components/UI/Page404/Page404'
import Footer from './components/Footer/Footer'

export default class App extends Component {
    constructor(props) {
        super(props)

        const locale = localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')) : 'ru'

        this.state = {locale}
    }

    setLocale = (locale) => {
        if (locale !== this.state.locale) {
            localStorage.setItem('locale', JSON.stringify(locale))
            this.setState({locale})
            showNotify(locale, 'notify.langChanged')
        }
    }

    sendMessage = (data) => {
        console.log(data)
        showNotify(this.state.locale, 'notify.messageSend')
    }

    render() {
        const {locale} = this.state
        return (
            <IntlProvider
                key={locale}
                locale={locale}
                defaultLocale="ru"
                messages={internationalization[locale]}>
                <div className="app" id={'app'}>
                    <Header
                        setLocale={this.setLocale}
                        locale={locale}
                    />
                    <div className="app__container">
                        <Switch>
                            <Route path={'/'} exact render={() => <MainPage sendMessage={this.sendMessage}/>}/>
                            <Route path={'/personal'} exact render={() => <PrivatePolicy locale={locale}/>}/>
                            <Route component={Page404}/>
                        </Switch>
                    </div>
                    <ScrollTop/>

                    <Footer/>
                </div>
            </IntlProvider>
        )
    }
}