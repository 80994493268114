// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        '': '',
        'notify.langChanged': 'Language changed successfully!',
        'notify.messageSend': 'Message sent!',
        'header.logo': 'Accelcomp',
        'header.company': 'Company',
        'header.expertise': 'Expertise',
        'header.webGeo':'WebGeo',
        'header.contacts': 'Contacts',
        'header.home': 'Home',
        'badge.text': 'HPC and simulations',
        'about.accelcomp': 'Accelcomp is a full-stack software development company with its main ' +
            'focus on computer-aided engineering and scientific software.',
        'about.offer': 'We offer cooperation in the field of scientifically and computationally challenging simulations' +
            ' of a wide range of processes. High quality numerical experiments and predictions are getting more accessible as computing power ' +
            'grows and utility software libraries are being developed. In case the existing software is not satisfactory' +
            ' in your domain for any reason, we can come up with a flexible and efficient individual solution.',
        'about.weCan': 'High quality numerical experiments and predictions are getting more accessible as computing power ' +
            'grows and utility software libraries are being developed. In case the existing software is not satisfactory' +
            ' in your domain for any reason, we can come up with a flexible and efficient individual solution.',
        'about.clusters': 'Based on the problem\'s needs in computing power we offer computing kernels for a range of ' +
            'architectures from workstations to heterogeneous computing clusters.\n',
        'about.priority': 'In every case our first aim is to deliver stable and reproducible simulations.',
        'expertise.listHeader': 'Simulation software relies on numerous components and we got professionals at every stage:',
        'expertise.item1': 'mathematical modelling',
        'expertise.item2': 'numerical approximations',
        'expertise.item3': 'high-performance computing kernels',
        'expertise.item4': 'automated deployment on computing clusters',
        'expertise.item5': 'selection of problem-specific hardware',
        'expertise.item6': 'web applications and cross-platform desktop applications',
        'expertise.item7': '3D-modelling tools',
        'expertise.item8': 'publication-level graphical output.',
        'webGeo.title':'WebGeo',
        'webGeo.about':'WebGeo - программный комплекс для геотехнического мониторинга и решения задач тепломассопереноса в многолетнемерзлых грунтах.',
        'webGeo.minConfig':'Минимальная конфигурация для расчетного ядра:',
        'webGeo.minItem1':'Центральный процессор:  — не менее 4 ядер частотой не ниже 2.5 ГГц',
        'webGeo.minItem2':'Графический процессор: — не менее 2000 CUDA-ядер, не менее 8ГБ оперативной памяти,' +
        'поколение не ниже Pascal (например, GTX 1080)',
        'webGeo.minItem3':'Оперативная память — не менее 32 ГБ DDR4 и не менее суммарной памяти графических процессоров, умноженной на два.',
        'webGeo.config':'Рекомендуемая конфигурация:',
        'webGeo.item1':'Центральный процессор:  — 8 ядер частотой 3 ГГц',
        'webGeo.item2':'Графический процессор: — 2x RTX A5000',
        'webGeo.item3':'Оперативная память — 128 ГБ DDR4',
        'contactForm.form': 'Contact form',
        'contactForm.leave': 'Leave us your contact details and we will get back to you',
        'contactForm.email': 'E-mail*',
        'contactForm.name': 'Name*',
        'contactForm.number': 'Number phone*',
        'contactForm.message': 'Message',
        'contactForm.required': 'Fields marked with " * " required.',
        'contactForm.send': 'Send',
        'contactForm.processingData': 'By clicking on the button, you consent to the processing of personal data and ' +
            'agree to the ',
        'contactForm.privatePolicy': 'privacy policy.',
        'footer.contacts': 'Contacts',
        'footer.located': 'We are located in Yaroslavl, Russia.',
        'footer.director': 'Director - Dmitry Glyzin',
        '404.notFound': 'Page not found!',
        '404.homepage': 'On the homepage!'
    },
    ru: {
        '': '',
        'notify.langChanged': 'Язык успешно сменен!',
        'notify.messageSend': 'Сообщение отправлено!',
        'header.logo': 'ООО "Акселкомп"',
        'header.company': 'Компания',
        'header.expertise': 'Предложения',
        'header.webGeo':'WebGeo',
        'header.contacts': 'Контакты',
        'header.home': 'Главная',
        'badge.text': 'Высокопроизводительные вычисления и компьютерное моделирование',
        'about.accelcomp': 'ООО "Акселкомп" — компания полного цикла разработки программ для автоматизации научных' +
            ' и инженерных расчетов. ',
        'about.offer': 'Мы предлагаем сотрудничество в области науко- и ресурсоемкого моделирования широкого круга ' +
            'процессов. Качественные вычислительные эксперименты и прогнозы становятся доступнее с развитием' +
            ' компьютерных мощностей и базовых библиотек программ. Мы сможем предложить гибкое и производительное ' +
            'индивидуальное решение в тех случаях, когда существующий софт не является удовлетворительным по каким-либо ' +
            'критериям.',
        'about.weCan': 'В зависимости от запросов инженеров (конечных пользователей) интерфейс программы может ' +
            'варьироваться от набора полей для ввода значений и компонентов 3D-моделирования до среды программирования' +
            ' на специфическом для задачи языке.',
        'about.clusters': 'С учетом требований задач моделирования к ресурсам мы предлагаем разработку вычислительных ядер' +
            ' для различных архитектур от производительных рабочих станций до гибридных вычислительных кластеров.',
        'about.priority': 'Во всех случаях надежность и повторяемость расчетов остается нашей приоритетной задачей.',
        'expertise.listHeader': 'ПО для моделирования складывается из множества компонентов, и у нас есть специалисты на ' +
            'каждой стадии процесса:',
        'expertise.item1': 'математические модели',
        'expertise.item2': 'сеточные аппроксимации',
        'expertise.item3': 'производительные вычислительные ядра',
        'expertise.item4': 'автоматизированное развертывание на вычислительных кластерах',
        'expertise.item5': 'подбор оптимального вычислительного оборудования',
        'expertise.item6': 'веб-приложения и кросплатформенные десктоп-приложения',
        'expertise.item7': 'интерфейсы 3D-моделирования',
        'expertise.item8': 'представление результатов на графиках публикационного качества.',
        'webGeo.title':'WebGeo',
        'webGeo.about':'WebGeo - программный комплекс для геотехнического мониторинга и решения задач тепломассопереноса в многолетнемерзлых грунтах.',
        'webGeo.minConfig':'Минимальная конфигурация для расчетного ядра:',
        'webGeo.minItem1':'Центральный процессор  — не менее 4 ядер частотой не ниже 2.5 ГГц',
        'webGeo.minItem2':'Графический процессор — не менее 2000 CUDA-ядер, не менее 8ГБ оперативной памяти, ' +
        'поколение не ниже Pascal (например, GTX 1080)',
        'webGeo.minItem3':'Оперативная память — не менее 32 ГБ DDR4 и не менее суммарной памяти графических процессоров, умноженной на два.',
        'webGeo.config':'Рекомендуемая конфигурация:',
        'webGeo.item1':'Центральный процессор  — 8 ядер частотой 3 ГГц',
        'webGeo.item2':'Графический процессор — 2x RTX A5000',
        'webGeo.item3':'Оперативная память — 128 ГБ DDR4',
        'contactForm.form': 'Форма связи',
        'contactForm.leave': 'Оставьте нам контактные данные, и мы свяжемся с вами',
        'contactForm.email': 'E-mail*',
        'contactForm.name': 'Имя*',
        'contactForm.number': 'Телефон*',
        'contactForm.message': 'Сообщение',
        'contactForm.required': 'Поля помеченные "*" обязательные для заполнения.',
        'contactForm.send': 'Отправить',
        'contactForm.processingData': 'Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c ',
        'contactForm.privatePolicy': 'политикой конфиденциальности.',
        'footer.contacts': 'Контакты',
        'footer.located': 'Мы базируемся в Ярославле',
        'footer.director': 'Ген. директор - Глызин Дмитрий Сергеевич',
        '404.notFound': 'Страница не найдена!',
        '404.homepage': 'На главную страницу!'
    }
}
