import React, {Component} from 'react'
import {FormattedMessage} from 'react-intl'
import './InputFields.scss'

export default class InputField extends Component {
    constructor(props) {
        super(props)

        this.input = React.createRef()

        this.state = {
            isValid: true
        }
    }

    onChange = () => {
        const {data, isRequired} = this.props
        const value = this.input.current.value
        if (data) {
            data.value = value
            let isValid = true
            if (isRequired) isValid = value.replace(/\s+/g, '') !== ''

            data.isValid = isValid
            if (isValid !== this.state.isValid) this.setState({isValid})
        }
    }

    render() {
        const {label, type} = this.props
        const {isValid} = this.state

        return (
            <div className="input-field">
                <label className={'text text_md'}><FormattedMessage id={label || ''}/></label>
                {type === 'message'
                    ? <textarea ref={this.input} onChange={this.onChange} cols="30" rows="3"/>
                    : <input
                        ref={this.input}
                        className={`${isValid ? '' : 'input-error'}`}
                        onChange={this.onChange}
                        type={'text'}
                    />
                }
            </div>
        )
    }
}