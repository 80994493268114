import React from 'react'
import './Footer.scss'
import Wrapper from '../UI/Wrapper/Wrapper'
import {FormattedMessage} from 'react-intl'

const Footer = () => {
    return (
        <section id={'contacts'} className={'footer'}>
            <Wrapper>
                <p className={'text text_xl-b text-center'}>
                    <FormattedMessage id="footer.contacts"/>
                </p>
                <p className={'text text_lg text-center'}>
                    <FormattedMessage id="footer.located"/>
                </p>
                <p className={'text text_lg text-center'}>
                    inbox@accelcomp.org
                </p>
                <p className={'text text_lg text-center'}>
                    +7 910 970 0798
                </p>
                <p className={'text text_lg text-center'}>
                    <FormattedMessage id="footer.director"/>
                </p>
            </Wrapper>
        </section>
    )
}

export default Footer
