import toaster from 'toasted-notes'
import internationalization from './internationalization'

export function showNotify(locale = 'en', id = '') {
    toaster.notify(internationalization[locale][id], {
        position: 'bottom-right',
        duration: 4000
    })
}

export function scrollTo(id = 'app') {
    const element = document.getElementById(id)
    element && element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    })
}