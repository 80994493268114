import React, {Component} from 'react'
import './ContactsForm.scss'
import {FormattedMessage} from 'react-intl'
import InputField from '../InputFields/InputField'
import NumberPhoneInput from '../InputFields/NumberPhoneInput'
import {NavLink} from 'react-router-dom'

export default class ContactsForm extends Component {
    constructor(props) {
        super(props)

        this.email = {value: '', isValid: true}
        this.name = {value: '', isValid: true}
        this.name = {value: '', isValid: true}
        this.number = {value: '', isValid: true}
        this.message = {value: '', isValid: true}

        this.state = {
            isError: false
        }
    }

    sendMessage = (e) => {
        e.preventDefault()
        if (this.email.isValid && this.email.value !== '' && this.name.isValid && this.name.value !== ''
            && this.number.isValid && this.number.value !== '') {
            this.props.sendMessage({
                email: this.email.value,
                name: this.name.value,
                number: this.number.value,
                message: this.message.value
            })
            this.state.isError && this.setState({isError: false})
        } else !this.state.isError && this.setState({isError: true})
    }

    render() {
        const {isError} = this.state
        return (
            <form className={'contacts-form'} onSubmit={this.sendMessage}>
                <p className={'text text_xl-b text-center'}>
                    <FormattedMessage id="contactForm.form"/>
                </p>

                <p className={'text text_lg text-center'}>
                    <FormattedMessage id="contactForm.leave"/>
                </p>
                <div className={'contacts-form__content'}>
                    <InputField
                        data={this.email}
                        type={'email'}
                        label={'contactForm.email'}
                        isRequired={true}
                    />
                    <InputField
                        data={this.name}
                        type={'text'}
                        label={'contactForm.name'}
                        isRequired={true}
                    />
                    <NumberPhoneInput
                        data={this.number}
                        label={'contactForm.number'}
                        isRequired={true}
                    />
                    <InputField
                        data={this.message}
                        type={'message'}
                        label={'contactForm.message'}
                        isRequired={true}
                    />

                    <div className={'text-center'}>
                        <small className={`contacts-form__small ${isError ? 'error' : ''}`}>
                            <FormattedMessage id={'contactForm.required'}/>
                        </small>
                        <button className={'mt-3'} type={'submit'}><FormattedMessage id="contactForm.send"/></button>
                        <div className={'text text_sm mt-4'}>
                            <FormattedMessage id={'contactForm.processingData'}/>
                            <NavLink to={'/personal'} className={`link`}>
                                <FormattedMessage id={'contactForm.privatePolicy'}/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}