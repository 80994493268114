import React, {Component} from 'react'
import './InputFields.scss'
import {IMaskInput} from 'react-imask'
import {FormattedMessage} from 'react-intl'

export default class NumberPhoneInput extends Component {
    state = {
        isValid: true
    }

    onChange = (value, mask) => {
        const {data, isRequired} = this.props
        const unmaskedValue = mask._unmaskedValue
        if (data) {
            data.value = unmaskedValue
            let isValid = true
            if (isRequired) isValid = unmaskedValue.replace(/\s+/g, '') !== ''
            data.isValid = isValid
            if (isValid !== this.state.isValid) this.setState({isValid})
        }
    }

    render() {
        const {label} = this.props
        const {isValid} = this.state

        return (
            <div className="input-field">
                <label  className={'text text_md'}><FormattedMessage id={label || ''}/></label>
                <IMaskInput
                    mask={'+{7}(000)000-00-00'}
                    unmask={false}
                    onAccept={this.onChange}
                    className={isValid ? '' : 'input-error'}
                />
            </div>
        )
    }
}